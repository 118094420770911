<template>
  <v-container>
    <v-row class="text-center mt-2">
      <v-col cols="3" class="d-none d-lg-flex"> </v-col>
      <v-col>
        <v-container>
          <v-row>
            <v-col>
              <p class="pt-6 text-h6">
                DETAIL
              </p>
            </v-col>
          </v-row>
          <v-card class="card" elevation="2">
            <v-form v-on:submit.prevent="submit()" ref="form">
              <v-row class="mx-md-4">
                <v-col>
                  <v-text-field
                    type="text"
                    label="Prénom"
                    placeholder="Prénom"
                    :rules="nameRules"
                    v-model="applicant.firstname"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    type="text"
                    label="Nom"
                    placeholder="Nom"
                    :rules="nameRules"
                    v-model="applicant.lastname"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mx-md-4">
                <v-col cols="5">
                  <v-text-field
                    type="email"
                    label="Email devinci"
                    placeholder="Email devinci"
                    :rules="emailRule"
                    v-model="applicant.email"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select
                    :items="schools"
                    label="École"
                    :rules="schoolRule"
                    v-model="applicant.school"
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-select
                    :items="years"
                    label="Année"
                    :rules="yearRule"
                    v-model="applicant.year"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="mx-md-4">
                <v-col>
                  <v-select
                    :items="post"
                    label="Poste"
                    v-model="applicant.post"
                  ></v-select>
                </v-col>
              </v-row>
              <div v-if="applicant.post == 'Tuteur'">
                <v-row class="mx-md-4">
                  <v-col>
                    <v-select
                      :items="materials[applicant.school]"
                      label="Matière"
                      outlined
                      multiple
                      chips
                      v-model="applicant.materials"
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
              <v-row class="mx-md-4">
                <v-col>
                  <v-select
                    label="Activité assos"
                    outlined
                    multiple
                    chips
                    :items="activiteAsso"
                    v-model="applicant.activity_asso"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="mx-md-4 mt-0">
                <v-col>
                  <v-text-field
                    type="text"
                    label="Blague"
                    placeholder="Blague"
                    v-model="applicant.joke"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mx-md-4 mt-0">
                <v-col>
                  <v-textarea
                    rows="1"
                    type="text"
                    label="Question"
                    placeholder="Question"
                    v-model="applicant.other"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row class="mx-md-4 mt-0">
                <v-col cols="5"> </v-col>
                <v-col>
                  <v-select
                    label="Statut"
                    :items="status"
                    v-model="applicant.status"
                  ></v-select>
                </v-col>
                <v-col cols="5"> </v-col>
              </v-row>
              <v-row class="mx-md-4 mt-0">
                <v-col>
                  <v-btn elevation="2" class="mt-3" @click="cancel()"
                    >ANNULER</v-btn
                  >
                </v-col>
                <v-col>
                  <v-btn elevation="2" type="submit" color="error" class="mt-3"
                    >MODIFIER</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-container>
      </v-col>
      <v-col cols="3" class="d-none d-lg-flex"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DetailPage",
  props: {},
  data: () => ({
    applicant: {},
    schools: ["ESILV", "EMLV", "IIM"],
    years: ["A1", "A2", "A3", "A4", "A5"],
    post: [
      "Tuteur",
      "Responsable communication",
      "Chargé d'animation visuelle",
      "Chargé de photo/vidéo",
      "Community manager",
      "Responsable vie associative",
      "Chargé évènement hebdomadaire",
      "Chargé grand évènement",
      "Chargé d'integration",
      "Chargé de communication interne",
      "Responsable organisation ESILV",
      "Chargé d'organisation ESILV",
      "Chargé data ESILV",
      "Responsable organisation EMLV",
      "Chargé d'organisation EMLV",
      "Chargé data EMLV",
      "Responsable organisation IIM",
      "Chargé d'organisation IIM",
      "Chargé data IIM",
    ],
    materials: {
      ESILV: ["Mathématiques", "Physique", "Informatique"],
      EMLV: [
        "English track",
        "Codage",
        "Droit",
        "Marketing",
        "Finance",
        "Innovation",
        "Management",
        "Excel",
        "Comptabilité",
        "Contrôle de gestion",
        "Fiscalité",
        "Analyse financière",
        "Fondamentaux de l'économie",
        "Etude de marché",
        "Vente",
      ],
      IIM: [
        "Jeux video",
        "Creation & design",
        "Développement Web",
        "3D",
        "Communication digitale",
      ],
    },
    activiteAsso: [
      "Sortir boire des bières / Restaurant 🍻",
      "Faire de sorties culturelles (musée, expo,...)",
      "Bowling, accrobranche, lazer game, cinéma, karting",
      "Jeux en ligne",
      "Autre",
      "Rien du tout",
    ],
    status: ['Accepted', 'Refused', 'Pending', 'Email sent', 'Interview booked', 'Review'],
    nameRules: [(v) => !!v || "Le nom/prénom est requis"],
    emailRule: [
      (v) => !!v || "L'e-mail est requis",
      (v) =>
        /.+@edu.devinci.fr/.test(v) || "Adresse email devinci doit être valide",
    ],
    schoolRule: [(v) => !!v || "L'école est requise"],
    yearRule: [(v) => !!v || "L'année est requise"],
  }),
  beforeCreate: function() {
    //Get data from api before loading page
    this.$http
      .get(
        "https://api-recrutement.leolearning.fr/applicants?id=" +
          this.$route.params.id,
        {
          headers: {
            Authorization: "Bearer " + this.$session.get("jwt"),
          },
        }
      )
      .then((response) => {
        this.applicant = response.data.result;
      })
      .catch((err) => {
        if (err.response.status == 404) {
          this.$router.push("/dashboard");
        } else if (err.response.status == 401) {
          this.$session.destroy();
          this.$router.push("/login");
        } else {
          console.log(err.response);
        }
      });
  },
  methods: {
    submit() {
      this.$http
        .patch(
          "https://api-recrutement.leolearning.fr/applicants?id=" +
            this.$route.params.id,
          {
            status: this.applicant.status,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$session.get("jwt"),
            },
          }
        )
        .then((response) => {
          console.log(response);
          //this.applicant = response.data.result
        })
        .catch((err) => {
          if (err.response.status == 404) {
            this.$router.push("/dashboard");
          } else if (err.response.status == 401) {
            this.$session.destroy();
            this.$router.push("/login");
          } else {
            console.log(err.response);
          }
        });
    },
    cancel() {
      this.$router.push("/dashboard");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
