<template>
  <detail-page />
</template>

<script>
import DetailPage from '../components/DetailPage.vue'

  export default {
    name: 'Detail',

    components: {
      DetailPage
    },

    beforeCreate: function (){
      if (!this.$session.exists()) {
          this.$router.push('/login')
      }
    }
  }
</script>
